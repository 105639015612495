






































import { Component, Vue } from "vue-property-decorator";
import SalesMenuBar from "./components/SalesMenuBar.vue";
import SalesTagCards from "./components/SalesimgTagCards.vue";
import SalesSnapCardList from "./components/SalesSnapCardList.vue";
import { Popup } from "vant";
import { getNow } from "@/utils/common.util";

@Component({
  components: {
    Popup,
    SalesMenuBar,
    SalesTagCards,
    SalesSnapCardList,
  },
})
export default class SpecialSales extends Vue {
  show = false;

  showTips(): void {
    this.show = !this.show;
  }

  handleFeedback(): void {
    this.$router.push({ name: "ContactUs", query: { time: String(getNow()) } });
    this.showTips();
  }
}
