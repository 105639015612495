
















import { Component, Emit, Vue } from "vue-property-decorator";

@Component({})
export default class SalesMenuBar extends Vue {
  @Emit()
  showTips(): void {
    return;
  }
}
